import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Table, Space } from 'antd';
import CreateEditClientModal from './createEdit';
import axios from 'axios'
import OrganizationModal from './organization/modal'
import AppTable from 'components/common/table/AppTable'

const { Column } = Table;

const ClientPage = () => {
  const defaultPagination = {
    current: 1,
    pageSize: 10,
  }
  const tableHeaders = [
    { title: 'Code', dataIndex: 'code' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Address', dataIndex: 'address' }
  ]

  const navigate = useNavigate()

  const [pagination, setPagination] = useState(defaultPagination)
  const [clientList, setClientList] = useState([])
  const [dialog, setDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [organizationDialog, setOrganizationDialog] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)


  // const openOrganizationModal = async (item) => {
  //   // console.log('item', item)
  //   await setSelectedClient(item)
  //   setOrganizationDialog(true)
  // }

  const openOrganizationPage = (item) => {
    // console.log('item', item)
    navigate({
      pathname: `/client/organization/${item.id}`,
      id: item.id
    });
  }

  const goToUser = (item) => {
    // console.log('item', item)
    navigate({
      pathname: `/client/detail/${item.id}`,
      id: item.id
    });
  }

  const onOpen = () => {
    setDialog(true)
  }
  const onClose = () => {
    setSelectedClient(null)
    setDialog(false)
    setIsEdit(false)
  }
  const onOk = async () => {
    setDialog(false)
    await getData()
  }
  const onEditClient = async (item) => {
    setSelectedClient(item)
    await setIsEdit(true)
    setDialog(true)
  }
  const tableChange = (value) => {
    const pagination = {
      current: value.current,
      pageSize: value.pageSize
    }
    setPagination(pagination)
  }

  const getData = async () => {
    const response = (await axios.get('api/clients')).data
    if (response.result) {
      // avoid console props key error
      const result = response.result.map((item, index) => {
        item.key = index
        return item
      })
      setClientList(result)
    }
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <Card
        title='Client'
        extra={<Button onClick={onOpen}>Create</Button>}
      >
        <Row>
          <Col span={24}>
            <CreateEditClientModal
              visible={dialog}
              item={selectedClient}
              isEdit={isEdit}
              onCancel={onClose}
              onOk={onOk}
            />
            <AppTable
              bordered="true"
              scroll={{ x: 1200 }}
              headers={tableHeaders}
              items={clientList}
              pagination={pagination}
              onChange={tableChange}
            >
              {{
                actions:
                <Column
                  title='Action'
                  fixed="right"
                  render={(item, record) => (
                    <>
                      <Space size="small">
                        {/* <Button onClick={() => openOrganizationModal(record)}>Organization</Button> */}
                        <Button onClick={() => goToUser(record)}>Users</Button>
                        {/* <Button onClick={() => openOrganizationPage(record)}>Organization Page</Button> */}
                        <Button onClick={() => onEditClient(item)}>Edit</Button>
                      </Space>
                    </>
                  )}
                >
                </Column>
              }}
            </AppTable>
            <OrganizationModal
              visible={organizationDialog}
              client={selectedClient}
              onCancel={() => setOrganizationDialog(false)}
            />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ClientPage;