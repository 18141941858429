import { setLoading } from 'reducers/loading';
import { store } from 'reducers/index';

export const Loading = {
  setLoading (value) {
    store.dispatch(setLoading(value));
  }
}

export const joinString = (items, separator = " - ") => {
  if (items && Array.isArray(items) && items.length > 0) {
    return items.filter(item => !!item).join(separator)
  }
  return ""
}