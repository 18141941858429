import React, { useState, useEffect, useCallback } from "react";
import { Button, Row, Col, Card, Table, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  // useLocation,
  useParams,
} from "react-router-dom";
import axios from "axios";
import CreateUserModal from "./createUser";
import { Loading } from "utils";

const ClientUserPage = ({ client, onCancel }) => {
  const defaultPagination = {
    current: 1,
    pageSize: 10,
  };
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [clientUsers, setClientUsers] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [createUserModal, setCreateUserModal] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  // const location = useLocation();
  const { id } = useParams();

  const columns = [
    { title: "Code", dataIndex: "code" },
    { title: "Username", dataIndex: "username" },
    { title: "Email", dataIndex: "email" },
    {
      title: "Role",
      dataIndex: "role",
      render: (_, record) => <div>{record.role.name}</div>,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          ></Button>
          <Button
            danger
            disabled
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          ></Button>
        </Space>
      ),
    },
  ];

  const onChange = (value) => {
    setLoading(true);
    const pagination = {
      current: value.current,
      pageSize: value.pageSize,
    };
    setPagination(pagination);
    setLoading(false);
  };

  const onCreate = () => {
    setEditModal(false);
    setCreateUserModal(true);
  };
  const onSuccess = async () => {
    setCreateUserModal(false);
    await getClientUsers(id);
  };

  const onCloseModal = () => {
    setCreateUserModal(false);
  };

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
    setCreateUserModal(true);
  };

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: "warning",
      async onOk() {
        try {
          Loading.setLoading(true);
          // TODO: Implement delete method
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  const getClientUsers = useCallback(async (id) => {
    if (id) {
      const response = (await axios.get(`api/clients/${id}/users`)).data;
      if (response.result) {
        const result = response.result.map((item, index) => {
          item.key = index;
          return item;
        });
        setClientUsers(result);
      }
    }
  }, []);

  useEffect(() => {
    if (id) {
      getClientUsers(id);
    }
  }, [id, getClientUsers]);

  return (
    <>
      <Card
        title="Users By Client"
        extra={<Button onClick={onCreate}>Create</Button>}
      >
        <Row>
          <Col span={24}>
            <CreateUserModal
              value={createUserModal}
              item={currentItem}
              isEdit={editModal}
              onSuccess={() => onSuccess()}
              onCancel={() => onCloseModal()}
              clientId={id}
            />
            <Table
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={clientUsers}
              pagination={pagination}
              loading={loading}
              onChange={onChange}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ClientUserPage;
