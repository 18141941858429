import React, { useState, useEffect } from 'react';
import { Form ,Layout, Input, Button, Card, Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuids4 } from 'uuid';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { setRole, setRoles, reset } from '../../reducers/role';
import { Roles } from '../../constants/role.constant';
import { Loading } from 'utils';

const package_json = require('../../../package.json');

const { Footer } = Layout;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // let isLoading = false
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData () {
      Loading.setLoading(true);
      dispatch(reset());
      localStorage.setItem('deviceId', '');
      localStorage.setItem('applicationState', '');
      localStorage.setItem('token', '');
      localStorage.setItem('refresh_token', '');
      localStorage.setItem('role', '');
      localStorage.setItem('user', JSON.stringify({}));
      Loading.setLoading(false);
    }
    fetchData()
  }, []);

  const checkAdmin = (value) => {
    return value === 'ROLE_ADMIN';
  }

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const login = JSON.stringify({
        username: values.username,
        password: values.password
      });
      const newUuid = uuids4();
      const response = await axios.post(`api/auth/signin`, login, {
        headers: {
          "Content-Type": "application/json",
          "x-request-id": newUuid
        }
      });
      const result = response.data.result;
      if (response.status === 200) {
        const userInfo = {
          id: result.id,
          username: result.username,
          email: result.email,
          roles: result.roles,
          client: result.client,
          organizationId: result.organizationId,
          saleRep: result.saleRep
        };

        axios.defaults.headers['x-access-token'] = result.accessToken;
        axios.defaults.headers['x-request-id'] = newUuid;

        const findRole = Roles.find((t) => t.value === result.role);
        if (!findRole) {
          setLoading(false);
          return;
        }
        dispatch(setRole(findRole.code));
        localStorage.setItem('role', findRole.code);
        localStorage.setItem('deviceId', newUuid);
        localStorage.setItem('user', JSON.stringify(userInfo));
        localStorage.setItem('token', result.accessToken);
        localStorage.setItem('refresh_token', result.refreshToken);
        setLoading(false);
        navigate("/");
      }
    }
    catch {
      setLoading(false);
      message.error('Incorrect Username/Password.', 2);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row type="flex" align="middle" justify="center" style={{ minHeight: '100vh' }}>
      <Col
        xs={20} sm={16} md={12} lg={8} xl={6}
      >
        <Card title="Login">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input username' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Username' />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input password' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder='Password'
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Footer style={{ textAlign: 'center' }}>Phnom Daily ©2021 Created by Weekend <b> Version { package_json.version }</b></Footer>
      </Col>
    </Row>
  );
};

export default Login;
