import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Table, Space, Tag, Button, Grid } from "antd";
import { NumericFormat } from "react-number-format";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import axios from "axios";
import {
  PrinterOutlined,
  MoreOutlined,
  EyeOutlined,
  SnippetsOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Confirm from "components/modals/Confirm";
import ViewAndUpdateProduct from "./modal/ViewAndUpdateProduct";
import ViewAndUpdateExpense from "./modal/ViewAndUpdateExpense";
import ViewAndUpdatePayment from "./modal/ViewAndUpdatePayment";
import PizzaInvoice from "pages/report/pizzaInvoiceReport/index";
import PizzaInvoicePreview from "pages/report/pizzaInvoiceReport/preview";
import DeliveryReport from "pages/report/deliveryReport";
import "./Sale.css";
import { useEffect } from "react";
import { ADMIN_ROLE } from "constants/role.constant";
import { Loading } from 'utils';

const { useBreakpoint } = Grid;

const TableForm = ({
  data,
  pagination,
  onTableChange,
  onLoadData,
  options,
}) => {
  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);

  const [dataList, setDataList] = useState([]);
  const screens = useBreakpoint();
  const [openViewAndUpdateProducr, setOpenViewAndUpdateProduct] =
    useState(false);
  const [openViewAndUpdateExpense, setOpenViewAndUpdateExpense] =
    useState(false);
  const [openViewAndUpdatePayment, setOpenViewAndUpdatePayment] =
    useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [invoiceReport, setInvoiceReport] = useState(null);
  const pizzaInvoiceRef = useRef(null);

  const [invoiceReportPreview, setInvoiceReportPreview] = useState(null);
  const pizzaInvoicePreviewRef = useRef(null);

  useEffect(() => {
    if (data) {
      setDataList(data);
      if (data.length > 0 && currentItem) {
        var newItem = data.find((item) => item.id === currentItem.id);
        if (newItem) {
          setCurrentItem(newItem);
        }
      }
    } else {
      setDataList([]);
    }
  }, [data]);

  const items = (item) => [
    // {
    //   key: '1',
    //   label: 'ការចំណាយ',
    //   onClick: () => onExpense(item)
    // },
    {
      key: "2",
      label: "ការទូទាត់",
      onClick: () => onReceivePayment(item),
    },
    {
      key: "3",
      label: "លុប",
      onClick: () => onDelete(item),
    },
  ];

  const onPrint = (record) => {
    setInvoiceReport(record);
    setTimeout(() => {
      print();
    }, 300);
  };
  const print = useReactToPrint({
    content: () => pizzaInvoiceRef.current,
    onAfterPrint: () => onAfterPrint(),
  });

  const onAfterPrint = () => {
    setInvoiceReport(null);
    setInvoiceReportPreview(null);
    onUpdateSuccess();
  };

  const onPrintPreview = (record) => {
    setInvoiceReportPreview(record);
    setTimeout(() => {
      printPreview();
    }, 300);
  };

  const printPreview = useReactToPrint({
    content: () => pizzaInvoicePreviewRef.current,
    onAfterPrint: () => onAfterPrint(),
  });

  // Expense
  const onExpense = (item) => {
    setCurrentItem(item);
    setOpenViewAndUpdateExpense(true);
  };

  const onCancelViewAndUpdateExpense = () => {
    setOpenViewAndUpdateExpense(false);
    setCurrentItem(null);
  };

  // Receive payment
  const onReceivePayment = (item) => {
    setCurrentItem(item);
    setOpenViewAndUpdatePayment(true);
  };

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: "warning",
      async onOk() {
        try {
          Loading.setLoading(true);
          const response = await axios.delete(`api/sales/${item._id}`);
          if (response.data.code === 200) {
            onUpdateSuccess();
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  const onCancelViewAndUpdatePayment = () => {
    setOpenViewAndUpdatePayment(false);
    setCurrentItem(null);
  };

  // Product
  const onViewAndUpdate = (item) => {
    setCurrentItem(item);
    setOpenViewAndUpdateProduct(true);
  };

  const onCancelViewAndUpdateProduct = () => {
    setOpenViewAndUpdateProduct(false);
    setCurrentItem(null);
  };

  const onUpdateSuccess = () => {
    onLoadData();
  };

  const columns = [
    {
      title: "№",
      dataIndex: "code",
    },
    {
      title: "កាលបរិច្ឆេទ",
      dataIndex: "date",
      responsive: ["xs", "sm"],
      render: (text, record) => (
        <div>{moment(record.date).format("YY-MM-DD")}</div>
      ),
    },
    ADMIN_ROLE.code == roleValue
      ? {
          title: "តំណាងចែងចាយ",
          dataIndex: "saleRepId",
          key: "saleRepId",
          render: (text, { saleRepId }) => (
            <div>{saleRepId && saleRepId.name}</div>
          ),
          responsive: ["sm"],
        }
      : null,
    {
      title: "លេខលំដាប់",
      dataIndex: "invoiceSequence",
    },
    {
      title: t("label.status"),
      dataIndex: "product",
      responsive: ["sm"],
      render: (sale, record) => (
        <div>
          {record.printCount > 0 ? (
            <Tag>{t("label.isPrinted")}</Tag>
          ) : (
            <Tag color="#f5222d">{t("label.isNotPrinted")}</Tag>
          )}
        </div>
      ),
    },
    {
      title: t("label.address"),
      dataIndex: "address",
      responsive: ["sm"],
    },
    {
      title: "តម្លៃក្រុមហ៊ុន",
      dataIndex: "totalWholesale",
      align: "right",
      responsive: ["xs", "sm"],
      render: (sale, record) => (
        <b>
          <NumericFormat
            value={record.totalWholesale}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" ៛"}
          />
        </b>
      ),
    },
    {
      title: "តម្លៃសរុបរួម",
      dataIndex: "totalAmount",
      responsive: ["xs", "sm"],
      align: "right",
      render: (sale, record) => (
        <b>
          <NumericFormat
            value={record.totalAmount}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" ៛"}
          />
        </b>
      ),
    },
    {
      title: t("action.operation"),
      key: "id",
      fixed: "right",
      responsive: ["sm"],
      render: (sale, record) => (
        <Space size="small">
          <Button
            onClick={() => onViewAndUpdate(record)}
            // disabled={!roleValues.includes(USER_ROLE.code)}
            icon={<EyeOutlined />}
          />
          <Button
            onClick={() => onPrintPreview(record)}
            icon={<SnippetsOutlined />}
          />
          <Button onClick={() => onPrint(record)} icon={<PrinterOutlined />} />
          {ADMIN_ROLE.code == roleValue && (
            <>
              <Dropdown
                menu={{
                  items: items(record),
                }}
                trigger={["click"]}
              >
                {/* <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <MoreOutlined />
                  </Space>
                </a> */}
                <Button
                  onClick={(e) => e.preventDefault()}
                  icon={<MoreOutlined />}
                ></Button>
              </Dropdown>
            </>
          )}

          {/* <ModalCreateExpense
            selectedSale={record}
            onLoadSale={onLoadData}
            pagination={pagination}
          />
          <ModalCreateExpense
            selectedSale={record}
            onLoadSale={onLoadData}
            pagination={pagination}
            isPayment={true}
            isExpense={false}
          /> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      {process.env.REACT_APP_NAME === "Pizza" && (
        <div>
          <PizzaInvoice item={invoiceReport} ref={pizzaInvoiceRef} />
          <PizzaInvoicePreview
            item={invoiceReportPreview}
            ref={pizzaInvoicePreviewRef}
          />
        </div>
      )}
      {process.env.REACT_APP_NAME === "Online" && (
        <div>
          <DeliveryReport item={invoiceReport} ref={pizzaInvoiceRef} />
          <DeliveryReport
            item={invoiceReportPreview}
            ref={pizzaInvoicePreviewRef}
          />
        </div>
      )}
      <Table
        rowKey="id"
        size="small"
        pagination={pagination}
        onChange={onTableChange}
        dataSource={dataList}
        columns={columns.filter((x) => x !== null)}
        // rowClassName={
        //   (record) => {
        //     return record.printCount > 0 ? (record.totalRevenue > 0 ? 'printedWithPayment' : 'printedWithoutPayment') : ((!record.hasOwnProperty('address') || record.address === '') ? 'printedWithoutAddress' : 'print');
        //   }
        // }
        summary={() => {
          const currentScreens = Object.entries(screens).filter(
            (screen) => !!screen[1]
          ).length;
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  colSpan={
                    ADMIN_ROLE.code === roleValue
                      ? currentScreens > 1
                        ? 6
                        : 2
                      : currentScreens > 1
                      ? 5
                      : 2
                  }
                  align="right"
                >
                  សរុប
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    <NumericFormat
                      value={options.totalWholesale}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" ៛"}
                    />
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    <NumericFormat
                      value={options.totalAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" ៛"}
                    />
                  </b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  colSpan={
                    ADMIN_ROLE.code === roleValue
                      ? currentScreens > 1
                        ? 6
                        : 2
                      : currentScreens > 1
                      ? 5
                      : 2
                  }
                  align="right"
                >
                  តំលៃ​បូក​សរុប
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    <NumericFormat
                      value={options.grandTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" ៛"}
                    />
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    <NumericFormat
                      value={options.grandTotalWholesale}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" ៛"}
                    />
                  </b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />

      <ViewAndUpdateProduct
        item={currentItem}
        open={openViewAndUpdateProducr}
        onCancel={() => onCancelViewAndUpdateProduct()}
        onSuccess={() => onUpdateSuccess()}
      />

      <ViewAndUpdateExpense
        item={currentItem}
        open={openViewAndUpdateExpense}
        onCancel={() => onCancelViewAndUpdateExpense()}
        onSuccess={() => onUpdateSuccess()}
      />

      <ViewAndUpdatePayment
        item={currentItem}
        open={openViewAndUpdatePayment}
        onCancel={() => onCancelViewAndUpdatePayment()}
        onSuccess={() => onUpdateSuccess()}
      />
    </>
  );
};

export default TableForm;
