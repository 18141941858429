import React, { useEffect } from 'react';
import { Modal, Form, Input, message } from 'antd';
import axios from 'axios';

const CreateClientModal = ({ visible = false, onCancel, onOk, isEdit = false, item = undefined }) => {
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  const [form] = Form.useForm();

  const create = async (data) => {
    const response = await axios.post('api/clients', data)
    if (response && response.data && response.data.code && response.data.code === 200) {
      message.success('Added Successful')
      form.resetFields()
      onOk()
    } else {
      message.error(response.data.message.message)
    }
  }

  const update = async (data, id) => {
    const response = await axios.put(`api/clients/${id}`, data)
    if (response && response.data && response.data.code && response.data.code === 200) {
      message.success('Update Successful')
      form.resetFields()
      onOk()
    } else {
      message.error(response.data.message.message)
    }
  }

  const submit = async () => {
    const data = {
      name: form.getFieldValue('name'),
      address: form.getFieldValue('address')
    }
    try {
      if (item && item.id) {
        update(data, item.id)
      } else {
        create(data)
      }
    } catch (error) {
      message.error(error)
    }
  }

  const close = () => {
    form.resetFields()
    onCancel()
  }

  useEffect(() => {
    if (isEdit && item) {
      form.setFieldsValue({
        name: item.name,
        address: item.address
      })
    }
  }, [isEdit, item, form])

  return (
    <>
      <Modal
        title={isEdit === true ? 'Edit' : 'Create'}
        visible={visible}
        width={600}
        onCancel={close}
        onOk={submit}
      >
        <Form
          form={form}
          {...formItemLayout}
        >
          <Form.Item
            label='Name'
            name="name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Address'
            name="address"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateClientModal;