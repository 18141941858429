import React, { useState } from "react";
import axios from "axios";
import { message, Button, Space, Tooltip, Checkbox } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  UserAddOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import AppTable from "components/common/table/AppTable";
import Confirm from "components/modals/Confirm";
import { CreateUserModal } from "components/special";
import { Loading } from "utils";
import CreateEditModal from "./modal/CreateEdit";
import CreateCategoryModal from "./modal/CreateCategory";
import ResetSeqModel from "./modal/ResetSeq";
import { useTranslation } from "react-i18next";
import { SALE_REP_URL } from "constants/api";

const TableForm = ({ data, onLoadData }) => {
  const { t, i18n } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isResetSeq, setIsResetSeq] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const headers = [
    {
      title: t("label.name"),
      dataIndex: "name",
    },
    {
      title: t("label.shopName"),
      dataIndex: "shopName",
    },
    {
      title: t("label.phoneNumber"),
      dataIndex: "phoneNumber",
    },
    {
      title: t("label.otherNumber"),
      dataIndex: "otherNumber",
    },
    {
      title: "លេខលំដាប់",
      dataIndex: "seq",
      align: "center",
      render: (text, record) => (
        <>{record.latestSeq.length > 0 ? record.latestSeq[0].seq : ""}</>
      ),
    },
    {
      title: t("label.changeOrderNumberDaily"),
      dataIndex: "isSeqByDay",
      align: "center",
      render: (text, record) => (
        <>
          <Checkbox checked={record.isSeqByDay}></Checkbox>
        </>
      ),
    },
    {
      title: "Username",
      render: (text, record) => (
        <>{record.userId ? record.userId.username : ""}</>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Space>
          <Tooltip
            placement="topLeft"
            color="geekblue"
            title={t("action.createUser")}
          >
            <Button
              icon={<UserAddOutlined />}
              onClick={() => onOpenCreateUser(record)}
              disabled={record.userId ? true : false}
            />
          </Tooltip>
          <Tooltip
            placement="topLeft"
            color="geekblue"
            title={t("action.organizeCategories")}
          >
            <Button
              icon={<AppstoreAddOutlined />}
              onClick={() => onCreateCategory(record)}
            ></Button>
          </Tooltip>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => onResetSeq(record)}
          ></Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          ></Button>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          ></Button>
        </Space>
      ),
    },
  ];

  const onOpenCreateUser = (item) => {
    setCurrentItem(item);
    setIsCreateUser(true);
  };

  const onResetSeq = (item) => {
    setCurrentItem(item);
    setIsResetSeq(true);
  };

  const onCreateCategory = (item) => {
    setCurrentItem(item);
    setIsCategory(true);
  };

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  };

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: "danger",
      async onOk() {
        try {
          Loading.setLoading(true);
          const response = await axios.delete(`api/sale-reps/${item.id}`);
          if (response.data.code === 200) {
            onLoadData();
          } else {
            message.error(response.data.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setEditModal(false);
    setIsResetSeq(false);
    setIsCreateUser(false);
    setIsCategory(false);
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
    setIsResetSeq(false);
    setIsCreateUser(false);
    setIsCategory(false);
  };

  const onCreateUser = async (data) => {
    Loading.setLoading(true);
    const obj = {
      ...data,
      saleRepId: data.id,
    };
    const response = await axios.post(`${SALE_REP_URL}/user`, obj);
    if (response.data.code === 200) {
      onLoadData();
      setCurrentItem(null);
      setIsCreateUser(false);
      Loading.setLoading(false);
      message.success("This data added in System.");
    } else {
      Loading.setLoading(false);
      message.info("Failed");
    }
  };

  return (
    <>
      <AppTable items={data} headers={headers} disableAction />
      <CreateUserModal
        value={isCreateUser}
        item={currentItem}
        isEdit
        onCreate={onCreateUser}
        onCancel={() => onCloseModal()}
      />
      <CreateCategoryModal
        value={isCategory}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <ResetSeqModel
        value={isResetSeq}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <CreateEditModal
        value={editModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  );
};

export default TableForm;
